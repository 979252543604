import React, { Component } from 'react';

import { SERVER_API } from './OsbBackend';
import PropTypes from 'prop-types';

import doDownloadFile from '../downloadFile.js';

class CsvSection extends Component {
    constructor(props){
        super(props);

        this.state = {
            csvUploadSuccess: '',
            lastUpload: '',
        };
        this.uploadNewCSV = this.uploadNewCSV.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
    }

    uploadNewCSV(event){
        console.log('Uplaod new CSV: ', event.target.files[0].name);

        let data = new FormData();
        data.append('file', event.target.files[0]);
        data.append('token', this.props.token);

        fetch(SERVER_API['cardListUpload'], {
            method: 'POST',
            body: data,
        }).then(
            response => response.json()
        ).then(responseData => {
            if (responseData.status === 'OK') {
                console.log('Uplaod CSV ok', responseData);
                this.setState({ lastUpload: "upload" }, () => {
                });
            } else {
                console.log('Uplaod CSV error:', responseData.list);
                let errorList = responseData.list.map((error, index) => {
                    if (index === 0){
                        return (<div key={index}><strong>{error.message}</strong></div>);
                    } else {
                        return (<div key={index}>Error in Line {error.linenumber}: {error.message}</div>);
                    }
                });
                this.setState({ lastUpload: 'error', errorList }, () => {
                });
            }
        }).catch(
            error => console.log('error', error)
        );
    }

    downloadCSV(){
        console.log('Download CSV');
        fetch(SERVER_API['cardListDownload'] + 'token=' + this.props.token, {
            method: 'POST',
        })
            .then((response) => response.json())
            .then(responseData => {
                if (responseData.status === 'OK') {
                    console.log('Download ok', responseData);
                    doDownloadFile(responseData.fileId, responseData.fileType, this.props.token);
                } else {
                    console.log('Download CSV error, backen send no CSV');
                    this.setState({ lastUpload: 'np CSV' }, () => {
                    });
                }

            }).catch(
                error => console.log('Download CSV error:', error)
            );
    }

    render() {
        return (
            <div className="csv-section">
                <p>
                    Hier können Sie die CSV-Zuordnungsliste herunterladen, daraufhin lokal aktualisieren und wieder hochladen.
                </p>

                <div className="buttons">
                    <a href="#" className="button active" rel="noopener noreferrer"
                        onClick={this.downloadCSV}>Download CSV</a>

                    <label className="button active" htmlFor="fileUpload">Upload CSV</label>
                    <input accept=".csv" type="file" name="" id="fileUpload" onChange={this.uploadNewCSV} />
                </div>
                { this.state.lastUpload !== '' && this.state.lastUpload === 'upload' && <div className="info">Upload of CSV successful</div>}
                { this.state.lastUpload !== '' && this.state.lastUpload !== 'upload' && <div className="error">{this.state.errorList}</div>}
            </div>
        );
    }
}

export default CsvSection;

CsvSection.propTypes = {
    token: PropTypes.string,
};
