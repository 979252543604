import React, { Component } from 'react';

import LoginForm from './LoginForm';
import UploadSelect from './UploadSelect';
import fetch from '../fetchWithTimeout'

import '../../scss/main.scss';


export const SERVER_API = {
    login:            process.env.BACKEND_URL + '/api/login?', /* username, password */
    logout:           process.env.BACKEND_URL + '/api/logout?', /* token */
    list:             process.env.BACKEND_URL + '/api/listCards?', /* token */
    cardUpload:       process.env.BACKEND_URL + '/api', /* token, pdf(s) */
    cardDelete:       process.env.BACKEND_URL + '/api/cardDelete?', /* token, cardId */
    cardDownload:     process.env.BACKEND_URL + '/api/cardDownload?', /* token, cardId */
    fileDownload:     process.env.BACKEND_URL + '/api/fileDownload?', /* token, fileType, fileId */
    cardListUpload:   process.env.BACKEND_URL + '/api/cardlistUpload', /* token, csv */
    cardListDownload: process.env.BACKEND_URL + '/api/cardlistDownload?', /* token */
    maintenance:      process.env.BACKEND_URL + '/api/maintenance?', /* token, active */
};

class OsbBackend extends Component {
    constructor(props){
        super(props);

        this.state = {
            userData: {
                username: '',
                password: '',
                isValid: false,
            },
            token: '',
            maintenance: false,
            validUser: false,
            loginError: false,
            serverError: false,
            loading: false,
        };
        this.doLogin = this.doLogin.bind(this);
        this.loginSpinner = this.loginSpinner.bind(this);
    }

    loginSpinner (userData){
        if ( userData.isValid === true){
            this.setState({
                loading: true,
                loginError: false,
                serverError: false,
                userData: {
                    username: userData.username,
                    password: userData.password,
                    brand: userData.brand,
                    isValid: true,
                },
            }, () => {
                this.doLogin();
            });
        }
    }

    doLogin (){
        fetch( SERVER_API['login'] + 'brand=' + encodeURIComponent(this.state.userData.brand) + '&' + 'username=' + encodeURIComponent(this.state.userData.username) + '&password=' + encodeURIComponent(this.state.userData.password), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }, 30000)
            .then(response => response.json())
            .then((responseData) => {
                console.log('responseData', responseData);
                if (responseData.status === 'OK'){
                    this.setState({
                        validUser: true,
                        loading: false,
                        token: responseData.token,
                        maintenance: responseData.maintenance,
                    }, () => {
                        console.log('Login ok');
                    });
                } else if (responseData.status === 'Failed'){
                    this.setState({
                        validUser: false,
                        loginError: true,
                        loading: false,
                    }, () => {
                        console.log('Login Failed');
                    });
                }
            }).catch(
                error => {
                    console.log('Login catch', error);
                    this.setState({
                        serverError: true,
                        loading: false,
                    }, () => {
                    });
                }
            );

    }

    onLogoutClick(){
        fetch( SERVER_API['logout'] + 'token=' + encodeURIComponent(this.state.token), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then((responseData) => {
                console.log('responseData', responseData);
                if (responseData.status === 'OK'){
                    this.setState({
                        validUser: false,
                        serverError: false,
                        maintenance: false,
                        token: '',
                    }, () => {
                        console.log('Logout ok');
                    });
                }
            }).catch(
                error => {
                    console.log(error);
                }
            );
    }

    onMaintenanceClick(){
        fetch( SERVER_API['maintenance'] + 'token=' + encodeURIComponent(this.state.token) + '&' + 'active=' + !this.state.maintenance, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then((responseData) => {
                console.log('responseData', responseData);
                if (responseData.status === 'OK'){
                    this.setState({
                        maintenance: responseData.maintenance,
                    }, () => {
                        console.log('Toggle maintenance');
                    });
                }
            }).catch(
                error => {
                    console.log(error);
                }
            );
    }

    render() {

        return (
            <div className="content">
                <h1>OSB Backend</h1>
                {this.state.loading && <div className="loading">
                    <h2>Processing...</h2>
                    <div className="loading-spinner"></div>
                </div>}
                {this.state.validUser && <a href="#" className="logout"
                    onClick={() => this.onLogoutClick()}>Logout</a>}
                {this.state.validUser && <a href="#" className="maintenance"
                    onClick={() => this.onMaintenanceClick()}>Toggle maintenance ({this.state.maintenance?'active':'inactive'})</a>}

                {!this.state.validUser && <LoginForm
                    onLoginClick={ this.loginSpinner }
                    loginError={this.state.loginError}
                    serverError={this.state.serverError}/>}

                {this.state.validUser && <UploadSelect
                    token={this.state.token}
                    onLoginClick={userData => this.setState({ userData })} />}
            </div>
        );
    }
}

export default OsbBackend;
