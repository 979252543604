import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LoginForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            username: '', // +wrauch',
            password: '', // 'test',
            brand: 'bmw', // bmw / mini
            isValid: true, // false
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    onUsernameChange(username){
        let loginState = this.state;
        loginState.username = username;
        this.setState({ username: username, isValid: loginState.username !== '' && loginState.password !== '' });
    }

    onPasswordChange(password){
        let loginState = this.state;
        loginState.password = password;
        this.setState({ password, isValid: loginState.username !== '' && loginState.password !== '' });
    }

    onLoginClick(){
        if (this.state.isValid){
            this.props.onLoginClick(this.state);
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onLoginClick();
        }
    }

    onChangeHandler(e) {
        const element = e.currentTarget;
        this.setState({ brand: element.value }, () => {
        });
    }

    render() {
        return (
            <div className="osb-form">
                <input className="username"  placeholder="Username" autoComplete="off" type="text"
                    value={this.state.username}
                    onChange={event => this.onUsernameChange(event.target.value)}
                />
                <input className="password" placeholder="Password" autoComplete="off" type="password"
                    value={this.state.password}
                    onKeyPress={this.handleKeyPress}
                    onChange={event => this.onPasswordChange(event.target.value)}
                />
                <label className="radio"><input
                    onChange={event => this.onChangeHandler(event)}
                    type="radio" name="brand" id="brand-bmw" value="bmw" checked={this.state.brand === 'bmw'} />BMW</label>
                <label className="radio"><input
                    onChange={event => this.onChangeHandler(event)}
                    type="radio" name="brand" id="brand-mini" value="mini" checked={this.state.brand === 'mini'} />Mini</label>
                <label className="radio"><input
                    onChange={event => this.onChangeHandler(event)}
                    type="radio" name="brand" id="brand-bmwi" value="bmwi" checked={this.state.brand === 'bmwi'} />BMWi</label>
                <div className="buttons">
                    <div className={ this.state.isValid && 'button active' || 'button'}
                        onClick={() => this.onLoginClick()}
                    >Login</div>
                </div>
                {this.props.loginError && <div className="loginError error">Login Daten ungültig</div>}
                {this.props.serverError && <div className="loginError error">Server down</div>}
            </div>
        );
    }
}

export default LoginForm;
