import { SERVER_API } from './components/OsbBackend';

export const doDownloadFile = (fileId, fileType, token) => {
    console.log('Download File', fileId, fileType);
    fetch(SERVER_API['fileDownload'] + 'fileId=' + fileId +  '&fileType=' + fileType +'&token=' + token, {
        method: 'GET',
    }).then(function(response) {
        let fileName = 'file.pdf';
        let fileType = 'application/pdf';
        response.headers.forEach(function(val, key) {
            console.log(key + ' -> ' + val);
            if (key === 'content-disposition') {
                fileName = val.replace(/"/g, '').split('filename=')[1];
            }
            if (key === 'content-type') {
                fileType = val;
            }
        });

        response.blob().then(function(blob) {
            let newBlob = new Blob([blob], { type: fileType });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
            }, 100);
        });
    })
};

export default doDownloadFile
