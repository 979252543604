import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PdfSection from './PdfSection';
import CsvSection from './CsvSection';

class UploadSelect extends Component {
    constructor(props){
        super(props);

        this.state = {
            activeSection: 'pdf', // csv
            password: '',
            isValid: false,
        };
    }

    render() {
        return (
            <div className="osb-form">
                <div className="osb-form-tab">
                    <div className={ this.state.activeSection === 'pdf' && 'button active' || 'button'}
                        onClick={() => this.onSwitchSectionClick('pdf')}
                    ><h2>OSB PDF</h2></div>
                    <div className={ this.state.activeSection === 'csv' && 'button active' || 'button'}
                        onClick={() => this.onSwitchSectionClick('csv')}
                    ><h2>OSB CSV</h2></div>
                </div>
                {this.state.activeSection === 'csv' && <div className="section">
                    <CsvSection
                        token={this.props.token}
                    />
                </div>}
                {this.state.activeSection === 'pdf' && <div className="section">
                    <PdfSection
                        token={this.props.token}
                    />
                </div>}
            </div>
        );
    }

    onSwitchSectionClick(newSection){
        this.setState({ activeSection: newSection });
    }
}

export default UploadSelect;

UploadSelect.propTypes = {
    token: PropTypes.string,
};
