import React, { Component } from 'react';
import PropTypes from 'prop-types';

import doDownloadFile from '../downloadFile.js';

import ReactTable from 'react-table';
import 'react-table/react-table.css'

import { SERVER_API } from './OsbBackend';

class PdfSection extends Component {
    constructor(props){
        super(props);

        this.state = {
            lastUpload: '',
            pdfItems : [],
            pageOfItems: [],
            loading: false,
        };
        this.loadPdfData();
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleSelectedFileResult = this.handleSelectedFileResult.bind(this);
        this.convertTimeStamp = this.convertTimeStamp.bind(this);
        this.convertPU = this.convertPU.bind(this);
        this.createDownloadLink = this.createDownloadLink.bind(this);
        this.uploadNewPDF = this.uploadNewPDF.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
    }

    handleSelectedFileResult(snapshot) {
        console.log('Uploaded a blob or file!', snapshot);
        this.setState({ lastUpload: snapshot.metadata.name }, () => {
            this.loadPdfData();
        });
    }

    loadPdfData(){
        fetch(SERVER_API['list'] + 'token=' + this.props.token, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);
                this.setState({ pdfItems: responseData.list }, () => {
                    console.log('Load ok');
                });
            });
    }

    deletePDF(cardId, pdfName){
        if (cardId !== ''){
            if (window.confirm("Do you really want to delete the PDF " + pdfName + "?")) {
                console.log('deletePDF', cardId, pdfName);
                fetch(SERVER_API['cardDelete'] + 'cardId=' + cardId + '&token=' + this.props.token, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                })
                    .then((response) => response.json())
                    .then(responseData => {
                        console.log('Delete ok', responseData);
                        this.setState({ lastUpload: 'delete' }, () => {
                            this.loadPdfData();
                        });
                    });
            }
        }
    }

    downloadPDF(cardId){
        console.log('Download PDF', cardId);
        fetch(SERVER_API['cardDownload'] + 'cardId=' + cardId + '&token=' + this.props.token, {
            method: 'POST',
        })
            .then((response) => response.json())
            .then(responseData => {
                console.log('Download ok', responseData);
                if (responseData.status === 'OK') {
                    doDownloadFile(responseData.fileId, responseData.fileType, this.props.token);
                } else {
                    console.log('Download PDF error, backen send no PDF');
                    this.setState({ lastUpload: 'np PDF' }, () => {
                    });
                }
            }).catch(
                error => console.log('Download PDF error:', error)
            );
    }

    replacePDF(event, pdfId){
        console.log('Replace PDF:', event.target.files, pdfId);
        this.handleSelectedFile(event.target.files, pdfId)
    }

    uploadNewPDF(event){
        console.log('Uplaod new PDF:', event.target.files[0].name);
        this.handleSelectedFile(event.target.files, 'new')
    }

    handleSelectedFile(files, cardId){
        console.log(files, cardId);

        this.setState({ loading: true });

        let action = files.length === 1 ? '/cardUpload' : '/cardsUpload';
        let FormDataType = files.length === 1 ? 'file' : 'files';
        let data = new FormData();

        for (let index = 0; index < files.length; index++) {
            data.append(FormDataType, files[index]);
        }

        data.append('cardId', cardId);
        data.append('token', this.props.token);

        fetch(SERVER_API['cardUpload'] + action, {
            method: 'POST',
            body: data,
        }).then(
            response => response.json()
        ).then(
            success => {

                if (success.status === 'OK') {
                    console.log('PDF upload success', success);
                    this.setState({ lastUpload: "upload", loading: false }, () => {
                        this.loadPdfData();
                    });
                } else {
                    console.log('PDF uplaod error:', success.infos);
                    let errorList = success.infos.map((error, index) => {
                        return (<div key={index}>Error: {error}</div>);
                    });
                    this.setState({ lastUpload: 'error', errorList }, () => {
                    });
                }
            }
        ).catch(
            error => console.log('error', error)
        );
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    convertTimeStamp(ts) {
        ts = parseInt(ts);
        let date = new Date(ts);
        let options = {
            //            weekday: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    convertPU(pu) {
        return (pu) ? [pu.substr(0, 2), pu.substr(-2)].join('/') : 'n/a';
    }

    createDownloadLink(filenameNotEmpty, id, name) {
        console.log(filenameNotEmpty, id, name);

        if (filenameNotEmpty) {
            return <a href="#" rel="noopener noreferrer" onClick={ () => this.downloadPDF(id) }>{name}</a>
        } else {
            return <span>no file available</span>
        }
    }

    render() {
        const alignRight = {
            width: '100%',
            textAlign: 'right',
        };

        const columns = [
            {
                Header: '',
                accessor: 'change',
                maxWidth: 40,
                sortable: false,
                Cell: row => (
                    <span>
                        <label htmlFor={'fileUpload' + row.original.id} title="Replace this PDF">
                            <span className={ !row.original.name && 'download-icon protect icon' || 'download-icon icon'} />
                        </label>
                        <input accept=".pdf" type="file" id={'fileUpload' + row.original.id} onChange={ (event) => this.replacePDF(event, row.original.id ) } />
                    </span>
                ),
            }, {
                Header: '',
                accessor: 'delete',
                maxWidth: 40,
                sortable: false,
                Cell: row => (
                    <span
                        className={ row.original.protect && 'trash-icon protect icon' || 'trash-icon icon'}
                        onClick={()=> this.deletePDF(!row.original.protect ? row.original.id : '', row.original.filename)}
                    />
                ),
            }, {
                Header: 'Derivate',
                accessor: 'name',
                maxWidth: 80,
                Cell: row => (
                    <div style={alignRight}>{(row.value) ? row.value : 'n/a'}</div>
                ),
            }, {
                Header: 'PU',
                accessor: 'pu',
                maxWidth: 60,
                Cell: row => (
                    <div>{this.convertPU(row.value)}</div>
                ),
            }, {
                Header: 'PDF',
                accessor: 'filename',
                maxWidth: 450,
                Cell: (row) => (
                    <span>{this.createDownloadLink(row.original.filenameNotEmpty, row.original.id, row.value, row.original.size)}</span>
                ),
                // Cell: row => (
                //     <span><a href="#" rel="noopener noreferrer" onClick={ () => this.downloadPDF(row.original.id) }>{row.value}</a> ({row.original.size})</span>
                // ),
            }, {
                Header: 'Size',
                accessor: 'size',
                maxWidth: 70,
                Cell: row => (
                    <span>{row.value}</span>
                ),
            }, {
                Header: 'Last Change',
                accessor: 'lastChange',
                maxWidth: 150,
                Cell: row => (
                    <div style={alignRight}>{this.convertTimeStamp(row.value)}</div>
                ),
            }, {
                Header: 'User',
                accessor: 'lastChangeBy',
                maxWidth: 150,
            },
        ];

        return (
            <div className="pdf-section">
                {this.state.loading && <div className="loading" />}


                <div>
                    <ReactTable
                        data = {this.state.pdfItems}
                        columns = {columns}
                        defaultPageSize = {10}
                    />
                </div>


                {/*
                <table className="pdf-list">
                    <thead>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>Derivate</td>
                            <td>PU</td>
                            <td>PDF, Filesize</td>
                            <td>Last Change</td>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.pageOfItems.map((pdf) => {
                        return (
                            <tr key={pdf.id}>
                                <DeleteCell deletePDF={this.deletePDF} {...pdf} />
                                <ReplaceCell replacePDF={this.replacePDF} {...pdf} />
                                <NameCell {...pdf} />
                                <PUCell {...pdf} />
                                <PDFCell {...pdf} />
                                <LastChangeCell {...pdf} />
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
*/}
                <div style={{ marginTop: '10px' }} className="buttons">
                    <label className="button active" htmlFor="fileUpload">Upload new PDF</label>
                    <input accept=".pdf" type="file" name="" id="fileUpload" onChange={this.uploadNewPDF} multiple="multiple" />
                </div>

                { this.state.lastUpload !== '' && this.state.lastUpload === 'upload' && <div className="info">Upload successful</div>}
                { this.state.lastUpload !== '' && this.state.lastUpload === 'delete' && <div className="info">Delete successful</div>}
                { this.state.lastUpload !== '' && this.state.lastUpload === 'np PDF' && <div className="error">Server send no PDF</div>}
                { this.state.lastUpload !== '' && this.state.lastUpload === 'error'  && <div className="error">{this.state.errorList}</div>}

            </div>
        );
    }
}

export default PdfSection;

PdfSection.propTypes = {
    token: PropTypes.string,
    pdfId: PropTypes.string,
};
